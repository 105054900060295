<template>
  <div class="nav">
    <div class="primary">
      <div>
        <a href="https://www.themode.io"><img class="logo" src="../assets/img/logo-animated.gif" alt="Noble Promises"></a>
      </div>
    </div>
    <div class="secondary">
      <div class="social">
        <span v-for="link in social" :key="link.name">
          <a target="_blank" :href="link.url">
            <img :src="link.icon" :alt="link.name" />
          </a>
        </span>
      </div>
      <div class="wallet-info" v-html="showStatusInfo()" @click="disconnect"></div>
    </div>
  </div>
</template>

<script>
import { contents, social } from '../config/data'
import { shorten } from '../utils'

const showStatusInfo = function() {
  const { comingSoon, connected, walletAddress } = this.statusInfo
  let info = ''
  if (comingSoon) {
    info = 'Coming Soon...'
  } else if (connected) {
    info = `Wallet Connected:<br>${shorten(walletAddress)}`
  } else {
    info = 'Connect Your Wallet To Begin'
  }
  return info
}

export default {
  data() {
    return {
      contents,
      social
    }
  },
  props: {
    statusInfo: {
      type: Object
    },
    disconnect: {
      type: Function
    }
  },
  methods: {
    showStatusInfo
  }
}
</script>
export const cards = [{
    name: 'Karan',
    image: require('../assets/img/gallery/1A.png'),
    show: true
  }, {
    name: 'Morgan Tanner',
    image: require('../assets/img/gallery/2A.png'),
    show: true
  }, {
    name: 'Mr. Grey',
    image: require('../assets/img/gallery/3A.png'),
    show: true
  }, {
    name: 'Jesse',
    image: require('../assets/img/gallery/9A.png'),
    show: true
  }, {
    name: 'Odion Tobi',
    image: require('../assets/img/gallery/6A.png'),
    show: false
  }, {
    name: 'Bananakin',
    image: require('../assets/img/gallery/10A.png'),
    show: false
  }, {
    name: 'Siamese Teddy',
    image: require('../assets/img/gallery/7A.png'),
    show: false
  }, {
    name: 'Potato',
    image: require('../assets/img/gallery/8A.png'),
    show: false
  }, {
    name: 'WomanNFT',
    image: require('../assets/img/gallery/5A.png'),
    show: false
  }, {
    name: 'Huckwirtz',
    image: require('../assets/img/gallery/4A.png'),
    show: false
  }
]

export const contents = [{
    name: 'Membership',
    url: 'https://www.themode.io/membership'
  }, {
    name: 'Scholarship',
    url: 'https://www.themode.io/scholarship'
  }, {
    name: 'NFT Trading',
    url: 'https://www.themode.io/nft-trading'
  }, {
    name: 'FAQs',
    url: 'https://www.themode.io/faqs'
  }, {
    name: 'A6 Experiment',
    url: 'https://www.themode.io/287f921a-ab85-4eb8-a390-618c25e069cf'
  }
]

export const social = [{
    name: 'Twitter',
    icon: require('../assets/img/social/twitter.svg'),
    url: 'http://twitter.com/mode_0x2021'
  }, {
    name: 'Discord',
    icon: require('../assets/img/social/discord.svg'),
    url: 'https://discord.gg/NeWR34QGzw'
  }, {
    name: 'Instagram',
    icon: require('../assets/img/social/instagram.svg'),
    url: 'https://www.instagram.com/themode0x2021'
  }
]

export const messages = {
  loading: {
    type: 'loading',
    title: 'Please Wait',
    body: '<p>Currently</p>Mining'
  },
  success: {
    type: 'success',
    title: 'Transaction Completed',
    body: null
  },
  error: {
    type: 'error',
    title: 'Transaction Failed',
    body: null
  },
  installMetaMask: {
    type: 'info',
    title: 'Install MetaMask',
    body: '<p>You need to install MetaMask.</p>Get it <a target="_blank" href="https://metamask.io/download">from here</a>'
  },
  endOfSale: {
    type: 'info',
    title: 'Sales closed',
    body: '<p>No more NFT left.</p>Thanks!'
  },
  maintenance: {
    type: 'info',
    title: 'Sales closed',
    body: '<p>Temporarily closed.</p>Will be back soon'
  },
  comingSoon: {
    type: 'info',
    title: 'Coming Soon',
    body: '<p>Almost Launching</p>Wait for it :-)'
  },
  notWhiteListed: {
    type: 'error',
    title: `Sorry. That didn't work`,
    body: 'You wallet<br>is not authorized to mint at this time'
  },
  canNotUnlock: {
    type: 'error',
    title: `Sorry. That didn't work`,
    body: 'Neither the requested NFT nor the provided wallet are associated with unlockable content'
  },
  verifyingUnlockables: {
    type: 'loading',
    title: 'Please Wait',
    body: 'Verifying...'
  },
  saveInfoSuccess: {
    type: 'success',
    title: 'You are all set!',
    body: 'Your information was successfully stored<p>You are also welcomed to come back anytime :-)'
  },
  saveInfoError: {
    type: 'error',
    title: `Oops! That didn't work`,
    body: 'Your information coud not be saved<p>But you can come back anytime and retry :-)'
  }
}

export const legendaryText = `
  <p><strong>Congratulations!</strong> As the holder of this legendary A6 Experiment NFT, you now own one of only 210 Legendary A6 Experiment NFTs. We are also pleased to inform you that effective immediately, you are now entitled to a 2% royalty on all royalty eligible<span>*</span> transactions that occur for the other 19  A6 Experiment NFTs in this collection (10 Common, 4 Uncommon, 3 Rare & 2 Epic). These royalties will begin to accrue immediately, which means that any tokens from this collection that mint subsequent to this Legendary token will earn you royalties. Mint royalties will be disbursed 1 week after launch, then quarterly from that point forward along with the secondary sales royalties<span>**</span>.</p>
  <p class="foot-notes">
  <span>*</span> Royalty eligible sales are those that take place on Opensea. As technology develops, the project will endeavor to expand royalty distributions for other platforms.<br>
  <span>**</span> Royalties follow the token, so you will only receive royalties for as long as you hold the NFT.
  </p>
`

export const USStates = [
  { name: 'Alabama', value: 'Alabama' },
  { name: 'Alaska', value: 'Alaska' },
  { name: 'Arizona', value: 'Arizona' },
  { name: 'Arkansas', value: 'Arkansas' },
  { name: 'California', value: 'California' },
  { name: 'Colorado', value: 'Colorado' },
  { name: 'Connecticut', value: 'Connecticut' },
  { name: 'Delaware', value: 'Delaware' },
  { name: 'Florida', value: 'Florida' },
  { name: 'Georgia', value: 'Georgia' },
  { name: 'Hawaii', value: 'Hawaii' },
  { name: 'Idaho', value: 'Idaho' },
  { name: 'Illinois', value: 'Illinois' },
  { name: 'Indiana', value: 'Indiana' },
  { name: 'Iowa', value: 'Iowa' },
  { name: 'Kansas', value: 'Kansas' },
  { name: 'Kentucky', value: 'Kentucky' },
  { name: 'Louisiana', value: 'Louisiana' },
  { name: 'Maine', value: 'Maine' },
  { name: 'Maryland', value: 'Maryland' },
  { name: 'Massachusetts', value: 'Massachusetts' },
  { name: 'Michigan', value: 'Michigan' },
  { name: 'Minnesota', value: 'Minnesota' },
  { name: 'Mississippi', value: 'Mississippi' },
  { name: 'Missouri', value: 'Missouri' },
  { name: 'Montana', value: 'Montana' },
  { name: 'Nebraska', value: 'Nebraska' },
  { name: 'Nevada', value: 'Nevada' },
  { name: 'New Hampshire', value: 'New Hampshire' },
  { name: 'New Jersey', value: 'New Jersey' },
  { name: 'New Mexico', value: 'New Mexico' },
  { name: 'New York', value: 'New York' },
  { name: 'North Carolina', value: 'North Carolina' },
  { name: 'North Dakota', value: 'North Dakota' },
  { name: 'Ohio', value: 'Ohio' },
  { name: 'Oklahoma', value: 'Oklahoma' },
  { name: 'Oregon', value: 'Oregon' },
  { name: 'Pennsylvania', value: 'Pennsylvania' },
  { name: 'Rhode Island', value: 'Rhode Island' },
  { name: 'South Carolina', value: 'South Carolina' },
  { name: 'South Dakota', value: 'South Dakota' },
  { name: 'Tennessee', value: 'Tennessee' },
  { name: 'Texas', value: 'Texas' },
  { name: 'Utah', value: 'Utah' },
  { name: 'Vermont', value: 'Vermont' },
  { name: 'Virginia', value: 'Virginia' },
  { name: 'Washington', value: 'Washington' },
  { name: 'West Virginia', value: 'West Virginia' },
  { name: 'Wisconsin', value: 'Wisconsin' },
  { name: 'Wyoming', value: 'Wyoming' }
]
import Contract from 'web3-eth-contract'
import { abi, contractAddress } from './abi.json'
import { abi as redeemableAbi, contractAddress as redeemableContractAddress } from './redeemable-abi.json'

const Config = function() {
  const mode = process.env.VUE_APP_ENV
  const comingSoon = process.env.VUE_APP_COMING_SOON == 'yes'
  const signUrl = process.env.VUE_APP_SIGNING_ENDPOINT
  const metadataUrl = process.env.VUE_APP_METADATA_URL
  const isDevelopment = mode == 'development'
  const isProduction = mode == 'production'
  const isStaging = mode == 'staging'
  const open = process.env.VUE_APP_MINTING_ENABLED == 'yes'
  const network = {
    name: 'main',
    chainId: 1,
    rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    explorer: 'https://etherscan.io'
  }
  if (isStaging) {
    network.name = 'rinkeby'
    network.chainId = 4
    network.rpcUrl = 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    network.explorer = 'https://rinkeby.etherscan.io'
  } else if (isDevelopment) {
    network.name = 'private'
    network.rpcUrl = process.env.VUE_APP_DEV_RPC_URL
    network.chainId = process.env.VUE_APP_DEV_CHAIN_ID,
    network.explorer = process.env.VUE_APP_DEV_EXPLORER_URL
  }
  Contract.setProvider(network.rpcUrl)
  const contract = new Contract(abi, contractAddress)
  const redeemableContract = new Contract(redeemableAbi, redeemableContractAddress)
  return {
    isDevelopment,
    isProduction,
    isStaging,
    network,
    open,
    abi,
    contractAddress,
    contract,
    redeemableContract,
    comingSoon,
    signUrl,
    metadataUrl
  }
}()

const ContractDetails = async function(contract) {
  const jobs = [
      contract.methods.name().call(),
      contract.methods.project().call(),
      contract.methods.symbol().call(),
      contract.methods.open().call(),
      contract.methods.mintingPrice().call(),
      contract.methods.mintingMax().call(),
      contract.methods.totalSupply().call(),
      contract.methods.minted().call(),
      contract.methods.owner().call(),
      contract.methods.baseURI().call(),
      contract.methods.whitelisted().call()
    ]

    const [
      name, 
      project,
      symbol,
      open,
      mintingPrice,
      mintingMax,
      totalSupply,
      totalMinted,
      owner,
      baseURI,
      whitelisted
    ] = await Promise.all(jobs)

    // Splitting the wei divission to avoid ts warning
    const ethPrice = mintingPrice/10000000000/100000000

    return {
      name,
      project,
      symbol,
      open,
      mintingPrice: ethPrice,
      mintingMax,
      totalSupply,
      totalMinted,
      owner,
      baseURI,
      whitelisted,
      available: totalSupply - totalMinted
    }
}

export { Config, ContractDetails }
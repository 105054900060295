<template>
  <Nav
    :statusInfo="statusInfo"
    :disconnect="disconnect"
  />
  <router-view
    :statusInfo="statusInfo"
    :connect="connect"
    :redeem="redeem" 
    :buy="buy"
    :alert="alert"
  ></router-view>
  <Footer />
</template>

<script>
import Web3 from 'web3'
import axios from 'axios'
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'
import { Config, ContractDetails } from './config'
import { messages } from './config/data'
import { shorten } from './utils'

let web3Driver = null
let alert = false
let statusInfo = {
  redeemAmount: 0
}

/**
 * Boots app to setup all event listeners and config values
 */
const boot = async function() {
  if (Config.comingSoon) {
    this.statusInfo = {
      message: messages.comingSoon,
      comingSoon: Config.comingSoon,
      authorizedWallet: false
    }
    this.alert = true
    return
  }
  this.statusInfo = await ContractDetails(Config.contract)
  this.statusInfo.authorizedWallet = !this.statusInfo.whitelisted
  const hasMetamask = typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask
  const { available } = this.statusInfo
  const { endOfSale, installMetaMask } = messages
  if (available <= 0) {
    this.statusInfo.message = endOfSale
    this.alert = true
  } else if (!hasMetamask) {
    this.statusInfo.message = installMetaMask
    this.alert = true
  } else {
    this.statusInfo.hasMetamask = true
    this.statusInfo.redeemAmount = 0
    this.web3Driver = new Web3(window.ethereum)
    const networkCheck = warppedNetworkChecked(this)
    this.emitter.on('wallet-connect', warppedWalletConnected(this))
    this.emitter.on('minting-ready', wrappedMintingReady(this))
    window.ethereum.on('accountsChanged', warppedAccountChange(this))
    window.ethereum.on('chainChanged', networkCheck)
    networkCheck()
  }
}

/**
 * Connect to wallet
 * Emmits the event wallet-connect with a payload describing the account link status
 */
const connect = function() {
  const connectResult = {
    success: false,
    result: null
  }
  window.ethereum.request({ method: 'eth_requestAccounts' }).then(res => {
    connectResult.success = true
    connectResult.result = res && res[0]
  }).catch(err => {
    connectResult.result = err
  }).finally(() => {
    this.emitter.emit('wallet-connect', connectResult)
  })
}

/**
 * Disconnects account from current execution
 * It is important to observe there si no way to do an actual disconnection
 * The only way is through Metamask UI 
 */
const disconnect = function() {
  const { connected } = this.statusInfo 
  if (connected) {
    this.statusInfo.connected = false
    this.statusInfo.walletAddress = null
    this.alert = false
  }
}

/**
 * The wrapper passes the this reference in order to access App object
 * in the out-of-context actual implementation of network check
 * @param {*} _this the this reference to App
 */
const warppedNetworkChecked = function(_this) {
  return function() {
    const { name } = Config.network
    _this.web3Driver.eth.net.getNetworkType().then(network => {
      if (network != name) {
        _this.alert = true
        _this.statusInfo.message = {
          type: 'error',
          title: 'Wrong network',
          body: `<p>Please, select ${name}</p>`
        }
      } else {
        _this.alert = false
      }
    })
  }
}

/**
 * The wrapper passes the this reference in order to access App object
 * in the out-of-context actual implementation of account change
 * @param {*} _this the this reference to App
 */
const warppedAccountChange = function(_this) {
  return function(wallets) {
    if (wallets && wallets.length) {
      _this.emitter.emit('wallet-connect', { success: true, result: wallets[0] })
    } else {
      _this.emitter.emit('wallet-connect', { success: false, result: null })
    }
  }
}

/**
 * The wrapper passes the this reference in order to access App object
 * in the out-of-context actual implementation of wallet connect
 * @param {*} _this the this reference to App
 */
const warppedWalletConnected = function(_this) {
  return function(connectResult) {
    if (connectResult.success) {
      _this.statusInfo.walletAddress = connectResult.result
      _this.statusInfo.connected = true
      // Check redeem availability
      _this.emitter.emit('minting-ready', connectResult.result)
    } else {
      _this.statusInfo.walletAddress = null
      _this.statusInfo.connected = false
    }
  }
}

/**
 * The wrapper passes the this reference in order to access App object
 * in the out-of-context actual implementation of mint method callback
 * @param {*} _this the this reference to App
 */
const wrappedMintCallback = function(_this) {
  return function (error, result) {
    if (!error) {
      _this.statusInfo.message = messages.loading
      _this.statusInfo.message.body = `
        <p>Transaction in progress:</p>
        <a target="_blank" href="${Config.network.explorer}/tx/${result}">
          ${shorten(result, 10, 8)}
        </a>`
      _this.statusInfo.trx = result
      _this.alert = true
    } else if (error.code == 4001) {
      _this.alert = false
    } else {
      _this.statusInfo.message = messages.error
      _this.statusInfo.message.body = result
      _this.alert = true
    }
  }
}

/**
 * The wrapper passes the this reference in order to access App object
 * in the out-of-context handler for mintin ready.
 * Meaning we got a wallet and potentially we checked for funds and holdings
 * @param {*} _this the this reference to App
 */
const wrappedMintingReady = function(_this) {
  return async function (walletAddress) {

    if (_this.statusInfo.whitelisted) {
      _this.statusInfo.message = messages.loading
      _this.statusInfo.message.body = `<p>Whitelist verification in progress</p>`
      _this.alert = true
      const response = await axios.post(Config.signUrl + '/wallets/sign', {
        wallet: walletAddress,
        tokenAmount: 0,
        redeemAmount: 0
      })
      _this.alert = false
      const whiteListValidation = response.data
      if (whiteListValidation.success) {
        _this.statusInfo.authorizedWallet = true
      } else {
        _this.statusInfo.authorizedWallet = false
      }
    }

    const [redeemableTokens, isRedeemed] = await Promise.all([
      Config.redeemableContract.methods.balanceOf(walletAddress).call(),
      Config.contract.methods.redeemed(walletAddress).call()
    ])
    if (redeemableTokens > 0 && !isRedeemed) {
      _this.statusInfo.canRedeem = true
    } else {
      _this.statusInfo.canRedeem = false
      _this.statusInfo.redeemAmount = 0
    }
  }
}

/**
 * Updates the minting form to include the redeeming amount
 */
const redeem = function() {
  this.statusInfo.redeemAmount = 1
}

/**
 * Executes the purchase action and handles success and fail results
 * @param {*} tokenAmount 
 */
const buy = async function(tokenAmount) {
  const {
    available,
    walletAddress,
    mintingPrice,
    redeemAmount,
    whitelisted
  } = this.statusInfo
  const {
    contractAddress,
    abi
  } = Config
  const purchaseAmount = tokenAmount + redeemAmount
  if (purchaseAmount > 0 && purchaseAmount <= available) {
    let whiteListValidation = {
      success: true,
      result: {
        signature: '0xcaffeefacade'
      }
    }
    if (whitelisted) {
      this.statusInfo.message = messages.loading
      this.statusInfo.message.body = `<p>Verification in progress:</p>`
      this.alert = true
      const response = await axios.post(Config.signUrl + '/wallets/sign', {
        wallet: walletAddress,
        tokenAmount,
        redeemAmount
      })
      whiteListValidation = response.data
    }
    if (!whiteListValidation.success) {
      this.statusInfo.message = messages.notWhiteListed
      this.statusInfo.message.body = `You wallet:<br>${shorten(walletAddress)}<br>is not authorized to mint yet`
      this.alert = true
    } else {
      const { signature } = whiteListValidation.result
      const feeMultiplier = process.env.VUE_APP_GAS_MULTIPLIER
      const ethAmount = tokenAmount * mintingPrice
      try {
        const weiAmount = this.web3Driver.utils.toWei(ethAmount.toFixed(6).toString(), 'ether')
        let options = {
          from: walletAddress,
          value: weiAmount,
        }
  
        const gasLimit = await this.web3Driver.eth.estimateGas(options);
        let gasMultiplier = tokenAmount * feeMultiplier;
        if (tokenAmount == 1) {
          gasMultiplier = tokenAmount * feeMultiplier * 2
        }
  
        options = {
          ...options,
          to: contractAddress,
          gas: parseInt(gasMultiplier * gasLimit),
        }
        const contract = new this.web3Driver.eth.Contract(abi, contractAddress)
        const response = await contract.methods.mint(signature, tokenAmount, redeemAmount).send(options, wrappedMintCallback(this))
        axios.post(Config.signUrl + '/trx/record', {
          wallet: walletAddress,
          tokenAmount,
          redeemAmount,
          trx: response.transactionHash
        }).catch(console.log)
        const msg = messages.success
        msg.body = `
          <p>Check Transaction:</p>
          <a target="_blank" href="${Config.network.explorer}/tx/${response.transactionHash}">
            ${shorten(response.transactionHash, 10, 8)}
          </a>`
        this.statusInfo.message = msg
        this.alert = true
      } catch(err) {
        if (err.code != 4001) {
          let msg = 'Unexpected error'
          if (err && err.message) {
            if (err.message.indexOf('insufficient funds') > -1) {
              msg = 'Your wallet does not have enough funds'
            } else if (err.message.indexOf(': revert') > -1) {
              const cause = err.message.substr(err.message.indexOf(': revert') + 8)
              msg = `${cause}`
            } else {
              msg = '<p>Transaction reverted by the EVM<br></p>'
            }
          }
          let explain = `
            <p>For more information you can</p>
            <a target="_blank" href="${Config.network.explorer}/tx/${this.statusInfo.trx}">
              check Etherscan
            </a>`
          this.statusInfo.message = messages.error
          this.statusInfo.message.body = msg + explain
          this.alert = true
        }
      }
    }
  }
}

export default {
  async created() {
    this.boot()
  },
  data() {
    return {
      web3Driver,
      statusInfo,
      alert
    }
  },
  components: {
    Nav,
    Footer
  },
  methods: {
    boot,
    connect,
    disconnect,
    redeem,
    buy
  }
}
</script>
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import mitt from 'mitt'
import App from './App.vue'
import MintingApp from './components/MintingApp/Mint.vue'
import UnlockablesApp from './components/UnlockablesApp.vue'
import './assets/css/styles.css'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'minting-app', component: MintingApp, props: true },
    { path: '/unlockables', component: MintingApp, props: true },
    { path: '/unlockables/:tokenId?', name: 'unlockables-app', component: UnlockablesApp, props: true },
  ]
})

const app = createApp(App)
app.use(router)
const emitter = mitt()
app.config.globalProperties.emitter = emitter
app.mount('#app')

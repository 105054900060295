
<template>
  <div class="unlockables">
    <div v-if="showAlert">
      <img :class="['icon', { 'spin': message.type == 'loading', 'inverted': message.type == 'loading' }]" :src="getTypeIcon()" :alt="message.type">
      <div class="title">{{ message.title }}</div>
      <div class="body" v-html="message.body"></div>
    </div>
    <div v-else-if="!statusInfo.connected">
      <h3>Welcome to A6 Experiment unlockable content</h3>
      <button class="btn connect" @click="connect">Connect Your Wallet To Begin</button>
    </div>
    <div v-else class="unlocked-content">
      <img v-if="!unlockableInfo.tokenUtilityType.isVideo" :src="unlockableInfo.tokenMeta.image" class="nft-image" />
      <video v-if="unlockableInfo.tokenUtilityType.isVideo" class="nft-image" controls>
        <source :src="unlockableInfo.tokenMeta.image" type="video/mp4">
      </video>
      <div v-if="unlockableInfo.tokenUtilityType.isLegendary">
        <p class="nft-type">Legendary</p>
        <div class="reading-text" v-html="unlockableInfo.tokenUtilityType.content"></div>
      </div>
      <div v-else-if="unlockableInfo.tokenUtilityType.isVeecon">
        <p class="nft-type">Veecon Ticket</p>
        <p>Please add you ETH Wallet for Receiving the VeeconTicket</p>
        <div class="form">
          <label for="deposits">ETH wallet address:</label>
          <input v-model="deposits" id="deposits" type="text" />
          <button @click="saveDeposits" >{{ submitting ? 'Please wait...' : 'Submit' }}</button>
        </div>
      </div>
      <div v-else-if="unlockableInfo.tokenUtilityType.isGift">
        <p class="nft-type">Gift Goat Gift</p>
        <p>Please add your postal address for receiving the Gift Goat Gift</p>
        <div class="form">
          <label for="address">address:</label>
          <input v-model="address" id="address" type="text" />
          <button @click="saveAddress" >{{ submitting ? 'Please wait...' : 'Submit' }}</button>
        </div>
      </div>
      <div v-else>
        <p>Sorry... No content for this NFT</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MessageBox from './MintingApp/MessageBox.vue'
import { Config, ContractDetails } from '../config'
import { messages, legendaryText, USStates } from '../config/data'

let unlockableInfo = {
  tokenOwner: null,
  tokenMeta: null,
  tokenUtilityType: null
}
let address = null
let deposits = null
let alert = false
let message = messages.verifyingUnlockables
let submitting = false

const boot = async function() {
  try {
    if (Config.comingSoon) {
      this.message = messages.comingSoon,
      this.alert = true
      return
    }
    this.unlockableInfo = await ContractDetails(Config.contract)
    if (this.tokenId && Number(this.tokenId) <= Number(this.unlockableInfo.totalMinted)) {
      const checkOwner = Config.contract.methods.ownerOf(`${this.tokenId}`).call()
      const checkToken = axios.get(`${Config.metadataUrl}/${this.tokenId}`)
      const [ owner, tokenMeta ] = await Promise.all([checkOwner, checkToken])
      this.unlockableInfo.tokenOwner = owner
      this.unlockableInfo.tokenMeta = tokenMeta.data
      this.emitter.on('wallet-connect', warppedWalletConnected(this))
    } else {
      throw new Error('Invalid Token ID')
    }
  } catch(err) {
    console.log(err)
    this.message = messages.canNotUnlock
    this.alert = true
  }
}

const warppedWalletConnected = function(_this) {
  return function(connectResult) {
    if (connectResult.success) {
      _this.deposits = connectResult.result
    }
  }
}

const getTypeIcon = function() {
  return require(`../assets/img/${this.message.type}.png`)
}

const getTokenUtilityType = function(tokenMeta) {
  let isVeecon = false
  let isGift = false
  let isLegendary = false
  let isVideo = tokenMeta.image.endsWith('.mp4')
  tokenMeta.attributes.forEach(trait => {
    const { trait_type, value } = trait
    if (trait_type == 'Special Utility' && value == 'Veecon Ticket') {
      isVeecon = true
    }
    if (trait_type == 'Special Utility' && value == 'Gift Goat Gift') {
      isGift = true
    }
    if (trait_type == 'Special Utility' && value == 'Unlockable') {
      isLegendary = true
    }
  })
  return { isVeecon, isGift, isLegendary, isVideo }
}

const watchForAlert = function() {
  const { tokenMeta, tokenOwner } = this.unlockableInfo
  const { walletAddress } = this.statusInfo
  const validTokenId = tokenMeta && this.tokenId
  const isOwner = walletAddress && (walletAddress.toLowerCase() == tokenOwner.toLowerCase())
  if (validTokenId && isOwner) {
    this.unlockableInfo.tokenUtilityType = getTokenUtilityType(tokenMeta)
    if (this.unlockableInfo.tokenUtilityType.isLegendary) {
      this.unlockableInfo.tokenUtilityType.content = legendaryText
    }
  }
  if (walletAddress && !isOwner) {
    this.message = messages.canNotUnlock
    this.alert = true
  }
  return this.alert || !validTokenId || (walletAddress && !isOwner)
}

const saveDeposits = async function () {
  this.submitting = true
  const payload = {
    tokenId: this.tokenId,
    wallet: this.statusInfo.walletAddress,
    deposits: this.deposits
  }
  const _this = this
  axios.post(Config.signUrl + '/saveunlockable/wallet', payload).then(resp => {
    const { success } = resp.data
    if (success) {
      _this.message = messages.saveInfoSuccess
    } else {
      _this.message = messages.saveInfoError
    }
  }).catch(err => {
    console.log(err)
    _this.message = messages.saveInfoError
  }).finally(() => {
    _this.submitting = false
    _this.alert = true
  })
}

const saveAddress = async function () {
  this.submitting = true
  const payload = {
    tokenId: this.tokenId,
    wallet: this.statusInfo.walletAddress,
    address: this.address
  }
  const _this = this
  axios.post(Config.signUrl + '/saveunlockable/address', payload).then(resp => {
    const { success } = resp.data
    if (success) {
      _this.message = messages.saveInfoSuccess
    } else {
      _this.message = messages.saveInfoError
    }
  }).catch(err => {
    console.log(err)
    _this.message = messages.saveInfoError
  }).finally(() => {
    _this.submitting = false
    _this.alert = true
  })
}

export default {
  async created() {
    this.boot()
  },
  data() {
    return {
      unlockableInfo,
      alert,
      message,
      address,
      deposits,
      USStates,
      submitting
    }
  },
  props: {
    tokenId: {
      // Support for number and empty string without warning
      type: null
    },
    statusInfo: {
      type: Object
    },
    connect: {
      type: Function
    },
    redeem: {
      type: Function
    },
    buy: {
      type: Function
    }
  },
  methods: {
    boot,
    getTypeIcon,
    watchForAlert,
    saveDeposits,
    saveAddress
  },
  comments: {
    MessageBox
  },
  computed: {
    showAlert: function () {
      return this.watchForAlert()
    }
  }
}
</script>
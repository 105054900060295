<template>
  <div class="minting">
    <MessageBox v-if="alert"
      :message="statusInfo.message"
    />
    <Form v-else
      :statusInfo="statusInfo"
      :connect="connect"
      :redeem="redeem"
      :buy="buy"
    />
  </div>
</template>

<script>
import Form from './Form.vue'
import MessageBox from './MessageBox.vue'

export default {
  data() {
    return {
    }
  },
  props: {
    statusInfo: {
      type: Object
    },
    connect: {
      type: Function
    },
    redeem: {
      type: Function
    },
    buy: {
      type: Function
    },
    alert: {
      type: Boolean,
      default: false
    }
  },
  components: { Form, MessageBox }
}
</script>
<template>
  <div class="dialog">
    <img src="../../assets/img/gallery/WebTitle.png">
    <div class="sales">
      <div v-if="!statusInfo.open">
        <p>Presale begins on:<br>Saturday 4/2 at 4:00 P.M. EST</p>
        <p>Public Sale begins on:<br>Saturday 4/2 at 4:00 P.M. EST</p>
      </div>
      <div v-else>
        <h3 v-if="statusInfo.whitelisted">The Presale is now live!</h3>
        <h3 v-else>The Public Sale is live!</h3>
        <p v-if="statusInfo.whitelisted && !statusInfo.connected">The Public Sale begins on Saturday April 2nd at 4:00 P.M. EST</p>
        <p v-else-if="statusInfo.whitelisted && statusInfo.authorizedWallet">
          Congratulations!<br>Your wallet address is on the presale whitelist.<br>
          The presale is live and will run until the public sale begins on Saturday April 2nd at 4:00 P.M. EST.<br>
          You may mint a maximum of {{ statusInfo.mintingMax }} tokens during the presale period
        </p>
        <p v-else-if="statusInfo.whitelisted && statusInfo.connected">
          Your wallet address is not on the whitelist for the presale.<br>
          If you feel this is an error, you may open a ticket in the MODE discord here <a target="_blank" href="https://discord.gg/x8aENnXq4h">MODE Discord Invite</a>,
          otherwise you are encouraged to mint when the public sale begins at 4:00 P.M. EST on Saturday April 2nd.
        </p>
        <p v-else-if="!statusInfo.whitelisted && statusInfo.connected">
          The Public Sale is live! You may mint a maximum of {{ statusInfo.mintingMax }} tokens per transaction
        </p>
        <hr>
        <br><div>Total Minted:<br>{{ statusInfo.totalMinted }} out of {{ statusInfo.totalSupply }}</div>
        <div v-if="statusInfo.authorizedWallet && statusInfo.connected">
          Max: {{ statusInfo.mintingMax }} per transaction
          <br>
          Price: {{ statusInfo.mintingPrice }} ETH Each
        </div>
      </div>
    </div>
    <div v-if="!statusInfo.open && statusInfo.connected">
      <div v-if="statusInfo.authorizedWallet">
        <p>Congratulations! Your wallet address is on the presale whitelist. The presale will begin on 3/31 at 4:00 P.M. EST and will run for 48 hours. You may mint a maximum of 10 tokens during the presale.</p>
      </div>
      <div v-else>
        <p>Your wallet address is not on the whitelist for the presale. If you feel this is an error, you may open a ticket in the MODE discord here <a target="_blank" href="https://discord.gg/x8aENnXq4h">MODE Discord Invite</a>, otherwise you are encouraged to mint when the public sale begins at 4:00 P.M. EST on Saturday April 2nd.</p>
      </div>
    </div>
    <div v-if="statusInfo.open && statusInfo.connected && statusInfo.authorizedWallet">
      <div v-if="statusInfo.canRedeem" class="redeem">
        <div>Hello <strong>MODE owner</strong>!</div>
        <div v-if="statusInfo.redeemAmount > 0">You are redeeming <span>1 Free Token</span></div>
        <div v-else>You can redeem <span>1 Free Token</span></div>
        <button v-if="statusInfo.redeemAmount > 0" class="btn confirm-redeem">Well done!</button>
        <button v-else class="btn advertise-redeem" @click="redeem">Get it now!</button>
        <div v-if="statusInfo.redeemAmount > 0">Gift Token already included. Enjoy!</div>
      </div>
      <div class="counter">
        <div class="less" @click="less">-</div>
        <div class="amount">{{ tokenAmount + statusInfo.redeemAmount }}</div>
        <div class="more" @click="more">+</div>
      </div>
      <div>Quantity</div>
    </div>
    <div v-if="statusInfo.open && statusInfo.connected && statusInfo.authorizedWallet" class="total-cost">Cost Total: {{ cost }} ETH</div>
    <button v-if="statusInfo.open && statusInfo.connected && statusInfo.authorizedWallet" class="btn mint" @click="mint">Complete Purchase</button>
    <button v-else class="btn connect" @click="connect">Connect Your Wallet To Begin</button>
  </div>
</template>

<script>
let cost = 0
let tokenAmount = 0

const more = function () {
  const { mintingMax, mintingPrice } = this.statusInfo
  if (this.tokenAmount < mintingMax) {
    ++this.tokenAmount
    this.cost = Number((this.tokenAmount * mintingPrice).toFixed(6))
  }
}

const less = function () {
  const { mintingPrice } = this.statusInfo
  if (this.tokenAmount > 0) {
    --this.tokenAmount
    this.cost = Number((this.tokenAmount * mintingPrice).toFixed(6))
  }
}

const mint = function() {
  this.buy(this.tokenAmount)
}

export default {
  data() {
    return {
      cost,
      tokenAmount
    }
  },
  props: {
    statusInfo: {
      type: Object
    },
    connect: {
      type: Function
    },
    redeem: {
      type: Function
    },
    buy: {
      type: Function
    }
  },
  methods: {
    more,
    less,
    mint
  }
}
</script>
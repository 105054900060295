<template>
  <div class="dialog message-box">
    <img :class="['icon', { 'spin': message.type == 'loading' }]" :src="getTypeIcon()" :alt="message.type">
    <div class="title">{{ message.title }}</div>
    <div class="body" v-html="message.body"></div>
  </div>
</template>

<script>
const getTypeIcon = function() {
  return require(`../../assets/img/${this.message.type}.png`)
}

export default {
  data() {
    return {
    }
  },
  props: {
    message: {
      type: Object
    }
  },
  methods: {
    getTypeIcon
  }
}
</script>
<template>
  <div class="footer">
    <img src="../assets/img/logo.png">
    <div><strong>©MODE, 2022. All Rights Reserved.</strong></div>
    <div><a href="https://www.themode.io/terms-conditions" target="_blank" class="url">Terms & Conditions</a></div>
    <div><a href="mailto:info@themode.io" class="email">info@themode.io</a></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>